<template>
  <ListViewTemplate
    view-name="venture"
    :items="ventures"
    :is-loading="isLoading"
    :pageTotalRecords="state.venture.totalRecords"
    :recordActivePage="state.venture.activePage"
    :recordPerPage="state.venture.venturePerPage"
    :onClickItem="(item) => onClickItem(item)"
    :getPageData= "getVenturesData"
    :toggleSlideover="(val) => isShowIdea = val"
    :listHeader="ideaHeader"
    :is-show="isShowIdea"
    :emptyViewBinding="emptyViewBinding"
  >
    <template v-slot:add>
      <AddIdea v-bind="{ ...c.addIdea, isShow: isShowIdea, isLoading: false, isSaving: isSaving }"
      @onClose="() => { isShowIdea = false, idea = null }" @saveIdea="(data, index) => saveIdea(data, index)"
      ref="idea" />
    </template>
  </ListViewTemplate>
</template>

<script>
import ListViewTemplate from '../components/organisms/ListViewItem/ListViewItem.vue'
import AddIdea from "@/components/organisms/Modals/AddIdea/AddIdea";
import { c } from '../components/constants.js';
import _ from 'lodash';

export default {
  components: {
    AddIdea,
    ListViewTemplate
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      isShowIdea: false,
      ideaHeader: {
        title: `Ideas and Ventures ${this.state.venture.ventures.length > 0 ? `(${this.state.venture.ventures.length})` : ``}`,
        btnText: 'Add Idea',
        showSearchbox:false
      },
      c,
      emptyViewBinding: {
        title: 'Get started by adding your first idea!',
        description: 'Once your add an idea, it will show up here',
        btnText: '+ Add an Idea',
        icon: 'LightBulbIcon'
      }
    }
  },
  methods: {
    onClickItem(venture) {
      this.$router.push({name: 'Venture Detail', params: {id: venture.id}})
    },
    async saveIdea(data, index) {
      this.isSaving = true
      let response = null;
      if (index === 0) {
        response = await this.actions.saveFormData({mutation:'saveVenture',params: data})
      } else if (index === 1 && this.idea && this.idea.id) {
        response = await this.actions.saveFormData({
          mutation:'saveVenture',
          params: { targetCustomerUser: { create: [{ researchSource: data.help, targetCustomer: { create: { type: data.type } } }] } },
          where: { id: this.idea.id }
        });
      } else if (index === 2 && this.idea && this.idea.id && this.idea.targetCustomerUser?.[0]?.targetCustomer) {
        const tagList = [
          { type: 'DEMOGRAPHIC', category: 'AGE', name: data?.age },
          { type: 'DEMOGRAPHIC', category: 'GENDER', name: data?.gender },
          { type: 'DEMOGRAPHIC', category: 'INCOME', name: data?.income },
          { type: 'DEMOGRAPHIC', category: 'ETHNICITY', name: data?.ethnicity },
        ]
        if(data?.interests && data?.interests?.length > 0) data?.interests?.map(interest => tagList.push({ type: 'INTEREST_ACTIVITY', category: 'INTEREST', name: interest }))
        if(data?.location && data?.location?.length > 0) data?.location?.map(lct => tagList.push({ type: 'LOCATION', category: 'COUNTRY', name: lct }))
        response = await this.actions.saveFormData({
          mutation:'saveVenture',
          params: { targetCustomerUser: { update: { data: { targetCustomer: { update: { tags: { create: tagList} }}}, where: {id: this.idea.targetCustomerUser?.[0]?.id }}}},
          where: { id: this.idea.id }
        });
      }
      if (response && response.id) {
        this.idea = response
      }
      this.isSaving = false
      if (index !== 5) this.$refs.idea.onNext();
    },
    async getVenturesData() {
      this.isLoading = true;
      await this.actions.venture.getVentures();
      console.log(this.state.venture.ventures, 'ventures')
      this.ventures = _.map(this.state.venture.ventures, venture => {
        return {
          id: venture.id,
          title: venture?.name || '',
          description: venture?.blurb || '',
          actionLabel: venture?.status || '',
          subtitles: [
            {
              icon: "MapIcon",
              name: "Target Customers",
              count: venture?.targetCustomerUser?.length
            },
            {
              icon: "ExclamationIcon",
              name: "Opportunities",
              count: venture?.opportunities?.length
            },
            {
              icon: "UsersIcon",
              name: "Team",
              count: venture?.users?.length
            },
          ],
        }
      });
      this.isLoading = false;
    }
  },
  async created() {
    await this.getVenturesData();
  },
}
</script>